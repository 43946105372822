<div class="app-container">
  <div class="sidebar-container">
    <div class="sidebar-top-container">
      <a href="/">
        <div><img class="nav-logo" src="assets/nav-bar/RAQN-Logo.svg" /></div>
        <div>
          <span class="d-none d-sm-inline pl-1 nav-logo-txt">Apps</span>
        </div>
      </a>

      <nav>
        @for (item of [
          {
            title: 'Central Configuration',
            link: config_portal,
            icon: 'assets/nav-bar/configuration-portal.svg'
          },
          {
            title: 'Control Center',
            link: control_center,
            icon: 'assets/nav-bar/control-center.svg'
          },
          {
            title: 'Service Performance',
            link: elastic_cloud,
            icon: 'assets/nav-bar/elastic-cloud.svg'
          },
          {
            title: 'API Management',
            link: api_management,
            icon: 'assets/nav-bar/api-management.svg'
          },
          {
            title: 'Supergraph',
            link: supergraph_dev_tools,
            icon: 'assets/nav-bar/supergraph-dev-tools.svg'
          },
          {
            title: 'Events Monitor',
            link: kafka_ui,
            icon: 'assets/nav-bar/kafka-icon.svg'
          },
          {
            title: 'E2E Testing Status',
            link: katalon_dashboard,
            icon: 'assets/nav-bar/test-icon.svg'
          }
        ]; track item.title) {
          <div>
            <a class="nav-link" [href]="item.link" rel="noopener">
              <img class="nav-icons" src="{{ item.icon }}" />
              <span class="margin-left">{{ item.title }}</span>
            </a>
          </div>
        }
      </nav>
      
    </div>
    <div class="sidebar-bottom-container">
      <div class="account-container">
        <div class="account-data">
          <span class="nav-displayname">{{ profile?.displayName }}</span>
          <span>{{ profile?.mail }}</span>
        </div>

        <div *ngIf="isLoggedIn">
          <button
            (click)="logout()"
            class="nav-link logout-button"
            mat-flat-button
          >
            <img
              class="icon_logout"
              src="assets/nav-bar/logout.svg"
              alt="Logout"
            />
            <span>Logout</span>
          </button>
        </div>
      </div>
      <div class="nav-bottom-line">
        <div class="p-logotxt">
          <div>Crafted with &#9825;</div>
          <div>by Henkel DX</div>
        </div>
        <div>
          <img
            class="nav-henkel-logo"
            src="assets/nav-bar/henkel_white_logo.svg"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="content-container">
    <router-outlet></router-outlet>

    <div class="footer">
      <div class="footer-text">
        <span><a (click)="navigateTo('/')">RAQN</a> | </span>
        <span
          ><a (click)="navigateTo('/info/terms-of-use')">Terms of Use</a> |
        </span>
        <span
          ><a (click)="navigateTo('/info/data-protection-statement')"
            >Data Protection Statement</a
          >
          |
        </span>
        <span
          ><a (click)="navigateTo('/info/note-for-us-residents')"
            >Note for US Residents</a
          >
          |
        </span>
        <span><a (click)="navigateTo('/info/imprint')">Imprint</a> | </span>
        <span><a (click)="navigateTo('/info/cookies')">Cookies</a> </span>
      </div>
      <div class="footer-text">
        <span>2024 © Henkel AG & Co. KGaA</span>
      </div>
    </div>
  </div>
  <div class="background-container"></div>
</div>

<main class="main"></main>
