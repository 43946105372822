<div class="info-container">
  <div *ngIf="pageName === 'main'" class="raqn-container">
    <div class="title">Welcome to RAQN Apps</div>
    <div class="raqn-logo">
      <img
        src="../assets/raqn.png"
        alt="RAQN Mascot"
        class="raqn-mascot raqn"
      />
      <img
        src="../assets/circle.png"
        alt="RAQN Mascot"
        class="raqn-mascot circle"
      />
      <app-like></app-like>
    </div>
  </div>
  <div *ngIf="pageName === 'terms-of-use'" class="info-text">
    <div class="info-title">Terms of Use</div>
    <p>
      Due to ongoing technical innovations and changes of the relevant legal
      framework the following Terms of Use need to be changed and/or amended by
      us from time to time. We therefore ask the user to review these Terms of
      Use before each visit to this website and to take into account any changes
      and/or amendments.
    </p>
    <h1>Introduction</h1>
    <p>
      This website is brought to you by Henkel AG &amp; Co. KGaA, Henkelstrasse
      67, 40589 Düsseldorf, Germany. The information available on this website
      about Henkel AG &amp; Co. KGaA&nbsp;(hereinafter referred to as "Henkel"),
      affiliated companies of Henkel and third parties has been put together
      with utmost care. However, we are unable to guarantee the completeness and
      correctness of the information. Henkel does not assume any liability for
      any mistakes in the contents of this website. Forward-looking statements
      made on this website have been given according to the best of our
      knowledge and belief. However, the results actually achieved by Henkel may
      differ greatly from these forward-looking statements, because they depend
      on a whole group of factors of a competitive and macroeconomic nature that
      are in some cases beyond the control of Henkel. Without prejudice to any
      legal obligations to amend forward-looking statements, Henkel has no
      intention of constantly updating all forward-looking statements contained
      in this website.
    </p>
    <p>
      By accessing this site, you accept without restriction or reservation the
      following General Terms of Use of the Website that you can view or print
      out.
    </p>
    <p>
      THIS WEBSITE AND THE INFORMATION CONTAINED HEREIN IS NOT INTENDED OR
      APPROVED FOR USE INSIDE THE UNITED STATES OF AMERICA OR U.S. CITIZENS OR
      RESIDENTS. SAID PERSONS ARE KINDLY ASKED TO CONTACT THEIR LOCAL HENKEL
      WEBSITE OR WEBSITES OF US- AFFILIATED COMPANIES OF HENKEL.
    </p>
    <h1>General Terms of Use of the Website</h1>
    <h3>1. Copyright Law</h3>
    <p>
      The pages of our site&nbsp;(contents, structure) are protected by
      copyright. In particular, any reproductions, adaptations, translations,
      storage and processing in other media, including storage or processing by
      electronic means, enjoy copyright protection. Any exploitation in whole or
      in part thereof shall require the prior written consent of Henkel AG &amp;
      Co. KGaA. Any reproduction of information or data, in particular the use
      of texts or parts thereof or image materials&nbsp;(except for Henkel press
      photos) or any other exploitation or dissemination, requires Henkel's
      prior written consent. The rights of dissemination and reproduction are
      held by Henkel.
    </p>
    <p>
      Henkel press photos may be used for editorial purposes only. Henkel press
      photos that are reproduced and/or electronically modified for editorial
      purposes must bear the copyright notice "© [Year] Henkel AG &amp; Co.
      KGaA. All rights reserved". Reprinting is free of charge, however, we
      request a copy for our files.
    </p>
    <p>© 2018 Henkel AG &amp; Co. KGaA, Düsseldorf. All rights reserved</p>
    <h3>2. Trademarks</h3>
    <p>
      The Henkel Oval logo and all product names and/or product get-ups on these
      pages are registered trademarks of Henkel AG &amp; Co. KGaA, its
      subsidiaries, affiliates or licensors. Any unauthorized use or abuse of
      these trademarks is expressly prohibited and constitutes a violation of
      trademark law, copyright law, other intellectual property rights or unfair
      competition law.
    </p>
    <h3>3. Disclaimer for Third Party Websites</h3>
    <p>
      a. The pages of this website contain links&nbsp;(i.e. "hyperlinks") to
      other websites that are operated by third parties and whose contents are
      not known to Henkel. Henkel merely facilitates access to such websites and
      assumes no responsibility whatsoever for their contents. Our links to
      third party websites are merely intended to make navigating easier for
      you. Statements shown on linked pages are not made our own. We rather
      explicitly dissociate from any and all contents of any and all third party
      pages linked on the pages of our website. In particular, we do not assume
      any liability for any breaches of statutory provisions or infringements of
      third party rights that occur on such pages.
    </p>
    <p>
      b. For websites to which hyperlinks are provided from the Henkel website,
      the owners of these websites are solely responsible for the content of
      these pages as well as for any sale of the products offered thereon and
      for the handling of any orders relating to the same.
    </p>
    <p>
      c. Henkel does not assume any liability for any infringement of any
      copyrights, trademarks or other intellectual property or personality
      rights that occurs on a page accessed by a hyperlink.
    </p>
    <p>
      d. In the event of an order or any other legal declaration in respect of a
      transaction, a contract is made solely between the user and the owner of
      the respective website or offering party or person presented therein,
      under no circumstances, however, between Henkel and the user. Please note
      the general business conditions of the respective supplier on the
      hyperlinked website.
    </p>
    <p>
      e. This disclaimer is valid for all links displayed on the henkel.com
      website and for any contents of websites to which the user is directed via
      such links.
    </p>
    <h3>4. General Disclaimer</h3>
    <p>
      Any liability of Henkel for damages arising out of the use of this website
      – irrespective of the legal cause, including tort – is limited to damages
      that are caused by intent or gross negligence. To the extent that there be
      a mandatory liability of Henkel as a result of a breach of material
      contractual duties, the total amount of any claim for damages is limited
      to the foreseeable damages. This shall not affect Henkel's liability under
      applicable Product Liability law or under any warranties given. The
      aforesaid limitations of liability also do not apply in the event of harm
      to life, bodily injury or harm to health of a person.
    </p>
    <p>
      Henkel employs great efforts to keep Henkel websites free from viruses,
      however, we are unable to guarantee any absence of viruses. For this
      reason, we recommend that care be taken to assure adequate protection
      against viruses&nbsp;(e.g. using virus scanners) before downloading
      documents and data.
    </p>
    <p>
      Henkel does not warrant any freedom from faults or errors of the services
      offered on the Henkel website nor their availability.
    </p>
    <h3>5. Forecasts and Declarations of Intent</h3>
    <p>
      Forward-looking statements on this Internet site have been made according
      to the best of our knowledge and belief. However, the results actually
      achieved by Henkel may differ greatly from these forward-looking
      statements, because they depend on a whole group of factors of a
      competitive and macroeconomic nature that are in some cases beyond the
      control of Henkel. Without prejudice to any legal obligations to amend
      forward-looking statements, Henkel has no intention of constantly updating
      all forward-looking statements contained in this website.
    </p>
    <h3>6. Henkel Products</h3>
    <p>
      The brands / products shown on this Internet website are examples of the
      brands / products available from companies of Henkel worldwide. Henkel
      does not warrant that a brands / product shown is also available in your
      country.&nbsp;
    </p>
    <h1>Miscellaneous</h1>
    <p>
      These General Terms of Use are governed by and construed in accordance
      with the laws of the Federal Republic of Germany without regard to the UN
      Convention on Contracts for the International Sale of Goods the
      application of which Convention to these General Terms of Use is hereby
      expressly excluded. To the extent permitted by applicable law, legal venue
      for all disputes relating to this website lies with Düsseldorf, Germany.
      Should any provision of these General Terms of Use be or become invalid,
      this shall not affect the validity of the remaining provisions.
    </p>
  </div>

  <div *ngIf="pageName === 'imprint'">
    <div class="info-title">Imprint</div>
    <h3>Service Provider:</h3>
    <p>
      Henkel AG &amp; Co. KGaA<br />
      represented by its personally liable partner:<br />
      Henkel Management AG, represented by the Management Board:<br />
      Carsten Knobel (Chairman),&nbsp;Marco Swoboda, Sylvie Nicol, Mark Dorn,
      Wolfgang König<br />
      Chairwoman of the Supervisory Board: Dr. Simone Bagel-Trah
    </p>
    <h3>Registered Office:</h3>
    <p>
      Henkelstrasse 67<br />
      40589 Düsseldorf, Germany
    </p>
    <h3>Postal Address:</h3>
    <p>
      40191 Düsseldorf, Germany<br />
      Phone: 0049-211-797-0
    </p>
    <h3>Commercial Register:</h3>
    <p>Registergericht Düsseldorf: HRB 4724</p>
    <h3>VAT ID No.:</h3>
    <p>DE 11 94 29 301</p>
  </div>

  <div *ngIf="pageName === 'data-protection-statement'">
    <div class="info-title">Data Protection Statement</div>
    <h1>General</h1>
    <p>
      Henkel AG &amp; Co. KGaA, hereinafter referred to as Henkel, respects the
      privacy of each person who visits our website. Now we would like to inform
      you of the type of data collected by Henkel and how they are used. You
      will also learn how you can exercise your data subjects’ rights.<br />
      <br />
      Any changes to this Data Protection Statement will be published on this
      page. This enables you to inform yourself at any time about which data we
      are collecting and how we use such data.<br />
      <br />
      This Data Protection Statement does not apply to websites accessible via
      hyperlinks on Henkel websites.
    </p>
    <h1>Collection, use and processing of personal data</h1>

    <p>
      Name of the responsible entity: Henkel AG &amp; Co. KGaA<br />
      <br />
      Address of the responsible entity: Henkelstrasse 67, 40589 Duesseldorf,
      Germany<br />
      <br />
      Henkel uses personal data primarily to provide this website to the users.
      Any further processing of the data only occurs on the basis of other
      statutory duties or permissions or if the respective user has consented
      towards Henkel. Henkel stores and processes data specifically for the
      following purposes:<br />
      <br />
      When users visit the website, Henkel automatically collects and stores
      certain data.&nbsp; This includes: the IP address or device ID allocated
      to the respective end-device which we need for transmitting requested
      content (e.g. especially content, texts, images, and product information
      as well as data files provided for downloading, etc.), the activity of
      users in the context of the website, the type of respective end-device,
      the used type of browser as well as the date and time of use.<br />
      <br />
      Henkel stores this information for a maximum of 7 days for the purpose of
      recognizing and pursuing misuse.<br />
      <br />
      Henkel uses this information also for improvements to the service’s
      presentation, features and functionalities as well as general
      administration tasks.<br />
      <br />
      Aside from this, Henkel deletes or anonymizes the data on use, including
      the IP addresses, without undue delay as soon as they are no longer needed
      for the above mentioned purposes.<br />
      <br />
      The processing and use of the data is based on statutory provisions which
      justify those operations on the grounds that (1) the processing is
      necessary for the provision of the website; or (2) Henkel has an
      overriding legitimate interest in ensuring and enhancing the functionality
      and error-free operation of the website and that it is tailored to the
      users’ needs.
    </p>
    <h1>Further Personal Data Processing</h1>
    <h3>Consumer request table</h3>
    <p>
      You may have provided your contact data via our contact form. We will use
      your data for the purpose of replying to your request.<br />
      <br />
      The processing and use of your data is based on statutory provisions which
      justify those operations on the grounds that the processing is necessary
      for processing your request.<br />
      <br />
      If you have not given consent to a longer storage duration e.g. in the
      course of our customer relationship management we will store the data no
      longer than needed in order to fulfill the aforementioned purpose or as
      long as required in case any statutory retention obligation applies.
    </p>
    <h3>Raffles</h3>
    <p>
      You may have provided your contact data and your address while
      participating in a raffle which we offer from time to time on our website.
      We will use your data for the purpose of conducting the raffle.<br />
      <br />
      We will not forward your data to any third party unless for the
      distribution of prizes or for conducting the raffle.<br />
      <br />
      The processing and use of your data is based on statutory provisions which
      justify those operations on the grounds that the processing is necessary
      for conducting the raffle.<br />
      <br />
      If you have not given consent to a longer storage duration e.g. in the
      course of our customer relationship management we will store the data no
      longer than needed in order to fulfill the aforementioned purpose or as
      long as required in case any statutory retention obligation applies.
    </p>
    <h3>Product test</h3>
    <p>
      You may have provided your contact data and your address while
      participating in product tests. We will use your data for the purpose of
      conducting the product test.<br />
      <br />
      We will not forward your data to any third party unless for the purpose of
      conducting the product test.<br />
      <br />
      The processing and use of your data is based on statutory provisions which
      justify those operations on the grounds that the processing is necessary
      for carrying out the product test.<br />
      <br />
      If you have not given consent to a longer storage duration e.g. in the
      course of our customer relationship management we will store the data no
      longer than needed in order to fulfill the aforementioned purpose or as
      long as required in case any statutory retention obligation applies.
    </p>
    <h3>Webinar</h3>
    <p>
      You may have provided your contact data, e.g your email address while
      participating in webinars. We will use your data for the purpose of
      providing the webinar.<br />
      <br />
      We will not forward your data to any third party unless for the purpose of
      conducting the webinar.<br />
      <br />
      The processing and use of your data is based on statutory provisions which
      justify those operations on the grounds that the processing is necessary
      for providing the webinar to you.<br />
      <br />
      If you have not given consent to a longer storage duration e.g. in the
      course of our customer relationship management we will store the data no
      longer than needed in order to fulfill the aforementioned purpose or as
      long as required in case any statutory retention obligation applies.
    </p>
    <h3>Surveys</h3>
    <p>
      You may have provided your contact data, e.g your email address while
      participating in a survey. We will use your data for the purpose of
      conducting the survey.<br />
      <br />
      We will not forward your data to any third party unless for the purpose of
      conducting the survey.<br />
      <br />
      The processing and use of your data is based on statutory provisions which
      justify those operations on the grounds that the processing is necessary
      for conducting the survey.<br />
      <br />
      If you have not given consent to a longer storage duration e.g. in the
      course of our customer relationship management we will store the data no
      longer than needed in order to fulfill the aforementioned purpose or as
      long as required in case any statutory retention obligation applies.
    </p>
    <h1>Consent / Withdrawal of Consent</h1>
    <h3>Customer Relationship &amp; Online Communities Management</h3>
    <p>
      You may have given consent that Henkel processes your contact data which
      you may have provided to Henkel, the content and circumstances&nbsp; of
      your electronic communication with Henkel such as contact forms or e-mail,
      your usage of Henkel websites, data on your participation in Henkel’s
      online trainings, activities on Henkel’s Social Media sites (in Blogs,
      Facebook Fanpages etc.) for the purposes of advertising (by post, in
      browser, in app and as specified below if you provide an additional
      consent), rendering technical advice such as usage of products or
      technologies, market research, and providing online services such as
      eLearning, webinars communication in relation to events, conducting
      surveys.&nbsp; To achieve the selected purposes Henkel may combine the
      above listed data with other data Henkel may have legally collected about
      you for instance from raffles or other website activities, and analyze the
      data in order to determine relevant advertising measures.
    </p>
    <p>
      In the course of this analysis Henkel applies an automated methodology
      that helps prioritize leads for sales. Contacts are categorized according
      to their online and off-line activities. Based on the category they are
      assigned to and the location they are the contacts are assigned to sales
      persons and receive different information on our products and services as
      well as advertising material.
    </p>
    <p>
      You may have given consent that Henkel may forward your personal data to a
      3rd Party agency for the following purpose:
    </p>
    <p>
      Your data might be forwarded to the distributor who is responsible for the
      respective region and product category.
    </p>
    <p>
      We will store the data only as long as the consent is valid or as long as
      required in case of any statutory retention obligations.<br />
      <br />
      You may withdraw your consent with effect for the future at any time by
      clicking the respective consent withdrawal hyperlink or by sending a
      letter or an e-mail to our data protection officer Mr. Frank Liebich,
      Henkelstrasse 67, D 40191 Düsseldorf, Germany (E-Mail:&nbsp;<a
        href="mailto:Datenschutz@henkel.com"
        target="_blank"
        >Datenschutz&#64;henkel.com</a
      >). The withdrawal of consent will not affect the lawfulness of processing
      based on consent before its withdrawal.<br />
      <br />
    </p>
    <h3>Electronic Mail Advertising</h3>
    <p>
      You may further have declared your consent that Henkel may contact you
      regarding offers, products and services regarding products from Henkel’s
      Adhesive Technologies range via electronic mail (e-mail, sms, mms, instant
      message) and/or telephone.<br />
      <br />
      We will use your data as set forth above under&nbsp;section Further
      Personal Data Processing of this Data Protection Statement to provide you
      with respective advertising measures.<br />
      <br />
      You may withdraw your consent with effect for the future at any time by
      clicking the respective consent withdrawal hyperlink or by sending a
      letter or an e-mail to our data protection officer Mr. Frank Liebich,
      Henkelstrasse 67, D 40191 Düsseldorf, Germany (E-Mail:&nbsp;<a
        href="mailto:Datenschutz@henkel.com"
        target="_blank"
        >Datenschutz&#64;henkel.com</a
      >). The withdrawal of consent will not affect the lawfulness of processing
      based on consent before its withdrawal.
    </p>
    <h1>Cookies, Pixel, Fingerprints</h1>
    <p>
      This website uses cookies and similar technologies. Technically necessary
      cookies will be used automatically. Other cookies (or similar
      technologies) will only be applied based on your prior consent. You can
      find more information on the Cookies used by our website and their
      purposes in our Cookie Policy. There, you will also find information on
      how to withdraw your consent for the future.
    </p>
    <p>
      Your preference is set for the named domain, per browser and computer.
      Therefore, if you visit our website at home and work or with different
      browsers, you need to refuse data storage with every device or browser.
    </p>
    <h1>Web Tracking</h1>
    <h3>Google Analytics</h3>
    <p>
      Provided you have consented, this website uses Google Analytics, a web
      analytics service provided by Google, Inc. (“Google”). For this purpose, a
      cookie is installed on your computer. The information generated by the
      cookie about your use of the website (including your IP address) will be
      transmitted to and stored by Google on servers in the United States.<br />
      <br />
      We have activated the IP anonymization, this means Google will
      truncate/anonymize the last octet of the IP address for Member States of
      the European Union as well as for other parties to the Agreement on the
      European Economic Area. Only in exceptional cases, the full IP address is
      sent to and shortened by Google servers in the USA.<br />
      <br />
      On behalf of the website provider Google will use this information for the
      purpose of evaluating your use of the website, compiling reports on
      website activity for website operators and providing other services
      relating to website activity and internet usage to the website provider.
      Google will not associate your IP address with any other data held by
      Google.<br />
      <br />
      <strong
        >Objection to data collection:<br />
        <br />
        You can also prevent Google’s collection and use of data (cookies and IP
        address) by changing your browser settings or by downloading and
        installing the browser plug-in available under&nbsp;</strong
      ><a
        href="https://tools.google.com/dlpage/gaoptout?hl=en"
        target="_blank"
        rel="noopener noreferrer"
        >https://tools.google.com/dlpage/gaoptout?hl=en</a
      >
    </p>
    <h3>Google DoubleClick</h3>
    <p>
      Provided you have consented, we use the Google DoubleClick function on our
      website in order to evaluate the use of the website and make it possible
      for us, Google and other advertisers who co-operate with DoubleClick to be
      able to provide you with user-relevant advertising. For this purpose, a
      cookie is installed on your computer. This cookie collects information on
      the advertising shown in your browser and which advertisements you click.
      The information generated by the cookie on your use of website will be
      transferred to a Google server in the US and stored there. Based on the
      information collected, interest-related advertisements are being displayed
      in your browser.<br />
    </p>

    <h3>Objection to data collection:</h3>
    <p>
      You can also permanently deactivate the DoubleClick cookie by changing
      your browser settings or with the aid of a browser plug-in. With the
      plug-in, your deactivation settings for this browser are retained, even if
      you delete all cookies. You can obtain the browser plug-in for permanent
      deactivation:
    </p>
    <a
      href="https://support.google.com/My-Ad-Center-Help/answer/12155656?visit_id=638096418485075949-640492971&amp;rd=1"
      target="_blank"
      rel="noopener noreferrer"
      >https://support.google.com/ads/answer/7395996</a
    >.
    <h3>Facebook Custom Audience Pixel</h3>
    <p>
      We participate in Facebook's Custom Audience program which - provided you
      have consented - enables us to display personalized ads to you when you
      also have subscribed to Facebook and log in into your Facebook account.
      This tool allows us to personalize our ads based on your usage of our
      website in order to provide ads specifically tailored for you. To this end
      we share your email address or phone number in a hashed format which
      permits Facebook to identify you in case you have also subscribed to
      Facebook.<br />
      <br />
    </p>

    <h3>Objection to data collection:</h3>

    <p>
      You can adjust your ad preferences by changing your browser settings or
      through your Facebook settings if you do not want to receive
      interest-based ads on Facebook.
    </p>

    <h3>Your Data Subject Rights / Data Protection Officer</h3>
    <p>
      At any time, you may assert the right to access your data. Additionally,
      provided that the respective requirements are met, you may assert the
      following rights:
    </p>
    <ul>
      <li>Right to rectification</li>
      <li>Right to erasure</li>
      <li>Right to restriction of processing</li>
      <li>
        Right to lodge a complaint with the respectively competent data
        protection supervisory authority
      </li>
      <li>Right to data portability (as of May 25th 2018)</li>
    </ul>

    <h3>Right to object</h3>
    <p>
      In case of processing activities involving your personal data that are
      carried out on basis of a legitimate interest of Henkel, you have the
      right to object against such processing of your personal data at any time
      for reasons resulting from your specific situation. Henkel will stop that
      processing unless Henkel can prove important reasons for the processing
      which deserve protection which outweigh your interests, rights and
      freedoms or if the processing serves to assert, exercise or defend against
      legal claims.
    </p>
    <p>
      In these cases, or if you have any other questions or wishes in connection
      with regard to your personal data, please send an e-mail or a letter to
      our data protection officer,&nbsp;Mr. Frank Liebich, Henkelstrasse 67,
      40589 Duesseldorf, Germany (E-Mail:
      <a href="mailto:Datenschutz@henkel.com" target="_blank"
        >Datenschutz&#64;henkel.com</a
      >).
    </p>
    <h3>Use of Social Media Plug-ins</h3>
    <p>
      Plug-ins (“plug-ins”) of different social media networks are embedded in
      our web pages. The associated services are provided by the respective
      companies (“providers”). These providers are:
    </p>
    <ul>
      <li>
        Facebook is operated by Facebook Inc., 1601 S. California Ave, Palo
        Alto, CA 94304, USA ("Facebook"). For an overview of the Facebook
        plug-ins and their appearance, go to:&nbsp;<a
          href="https://developers.facebook.com/docs/plugins"
          target="_blank"
          rel="noopener noreferrer"
          >https://developers.facebook.com/docs/plugins</a
        >
      </li>
    </ul>
    <ul>
      <li>
        Twitter is operated by Twitter Inc., 1355 Market St, Suite 900, San
        Francisco, CA 94103, USA (“Twitter”). For an overview of the Twitter
        buttons and their appearance, go to:&nbsp;<a
          href="https://twitter.com/about/resources/buttons"
          target="_blank"
          rel="noopener noreferrer"
          >https://twitter.com/about/resources/buttons</a
        >
      </li>
    </ul>
    <ul>
      <li>
        Google+ and YouTube are provided by Google Inc., 1600 Amphitheater
        Parkway, Mountainview, California 94043, USA (“Google”). For an overview
        of the Google+ plug-ins and their appearance, go to:&nbsp;<a
          href="https://developers.google.com/+/web/"
          target="_blank"
          rel="noopener noreferrer"
          >https://developers.google.com/+/web/</a
        >; for the YouTube plugins, go to:&nbsp;<a
          href="https://developers.google.com/youtube/subscribe/"
          target="_blank"
          rel="noopener noreferrer"
          >https://developers.google.com/youtube/subscribe/</a
        >
      </li>
    </ul>
    <ul>
      <li>
        Instagram is provided by Instagram LLC., 1601 Willow Road, Menlo Park,
        CA 94025, USA („Instagram“). For an overview of the Instagram plug-ins
        and their appearance, go to:&nbsp;<a
          href="http://blog.instagram.com/post/36222022872/introducing-instagram-badges"
          target="_blank"
          rel="noopener noreferrer"
          >http://blog.instagram.com/post/36222022872/introducing-instagram-badges</a
        >
      </li>
    </ul>
    <ul>
      <li>
        LinkedIn is provided by LinkedIn Corporation, 2029 Stierlin Court,
        Mountain View, CA 94043, USA (“LinkedIn”). For an overview of the
        LinkedIn plug-ins and their appearance, go to:&nbsp;<a
          href="https://developer.linkedin.com/plugins"
          target="_blank"
          rel="noopener noreferrer"
          >https://developer.linkedin.com/plugins#</a
        >
      </li>
    </ul>
    <p>
      To increase the protection afforded to your data when you visit our web
      pages, these plug-ins are implemented as “2-click buttons.” This form of
      integration ensures that, when accessing a page from within our website
      containing such plug-ins, you are not automatically connected to the
      providers’ servers. Only if you activate the plug-ins and thereby permit
      for data transmission, the browser will create a direct link to the
      providers’ servers. The content of the various plug-ins is then
      transmitted by the provider concerned directly to your browser and then
      displayed on your screen.<br />
      <br />
      The plug-in tells the provider which of our website pages you have
      accessed. If, while viewing our website, you are logged into your user
      account with the respective provider, he is able to collate your interest,
      i.e. the information you are accessing, with your user account. On making
      use of any of the plug-in functions (e.g. clicking the “Like” button,
      leaving a comment), this information too will be transmitted by the
      browser directly to the provider for retention.<br />
      <br />
      Further information on the collection and use of data by the providers and
      on the rights and possibilities available to you for protecting your
      privacy in these circumstances can be found in the providers’ data
      protection/privacy policies:
    </p>
    <ul>
      <li>
        Data protection/privacy policy issued by Facebook:&nbsp;<a
          href="http://www.facebook.com/policy.php"
          target="_blank"
          rel="noopener noreferrer"
          >http://www.facebook.com/policy.php</a
        >
      </li>
    </ul>
    <ul>
      <li>
        Data protection/privacy policy issued by Twitter:&nbsp;<a
          href="https://twitter.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >https://twitter.com/privacy</a
        >
      </li>
    </ul>
    <ul>
      <li>
        Data protection/privacy policy issued by Google:&nbsp;<a
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
          >https://policies.google.com/privacy</a
        >
      </li>
    </ul>
    <ul>
      <li>
        Data protection/privacy policy issued by Instagram:&nbsp;<a
          href="https://help.instagram.com/155833707900388/"
          target="_blank"
          rel="noopener noreferrer"
          >https://help.instagram.com/155833707900388/</a
        >
      </li>
    </ul>
    <ul>
      <li>
        Data protection/privacy policy issued by LinkedIn:&nbsp;<a
          href="https://www.linkedin.com/legal/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          >https://www.linkedin.com/legal/privacy-policy</a
        >
      </li>
    </ul>
  </div>

  <div *ngIf="pageName === 'cookies'">
    <div class="info-title">Cookies</div>

    <h1>Cookie List</h1>
    <p>
      A cookie is a small piece of data (text file) that a website – when
      visited by a user – asks your browser to store on your device in order to
      remember information about you, such as your language preference or login
      information. Those cookies are set by us and called first-party cookies.
      We also use third-party cookies – which are cookies from a domain
      different than the domain of the website you are visiting – for our
      advertising and marketing efforts. More specifically, we use cookies and
      other tracking technologies for the following purposes:
    </p>

    <h3>Strictly Necessary Cookies</h3>
    <p>
      These cookies are necessary for the website to function and cannot be
      switched off in our systems. They may be set in response to actions made
      by you which amount to a request for services, such as setting your
      privacy preferences, logging in, or filling out forms. You can set your
      browser to block or alert you about these cookies, but some parts of the
      site may not then work.
    </p>

    <table>
      <thead>
        <tr>
          <th scope="col">Cookie Subgroup</th>
          <th scope="col">Cookies</th>
          <th scope="col">Cookies used</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>tdx.henkel.com</td>
          <td>
            <a
              href="https://cookiepedia.co.uk/cookies/OptanonAlertBoxClosed"
              rel="noopener"
              target="_blank"
              aria-label="OptanonAlertBoxClosed Opens in a new Tab"
            >
              OptanonAlertBoxClosed </a
            >,
            <a
              href="https://cookiepedia.co.uk/cookies/OptanonConsent"
              rel="noopener"
              target="_blank"
              aria-label="OptanonConsent Opens in a new Tab"
            >
              OptanonConsent
            </a>
          </td>
          <td>First Party</td>
        </tr>
      </tbody>
    </table>

    <h3>Opt Out of Sale/ Sharing of Personal Data and Targeted Advertising</h3>
    <p>
      Under applicable privacy laws, you have the right to opt-out of (a) the
      “sale” of your personal information to third parties, (b) the sharing of
      personal information for cross-context behavioral advertising, and (c)
      processing for purposes of targeted advertising. Cookies under this
      heading collect information for analytics, personalized experiences, and
      for targeted advertising (including on social media). You may exercise
      your opt-out right with respect to data shared by these cookies by using
      this toggle switch. If you opt out, we will not be able to offer you
      personalized ads using data these cookies would have collected. For
      further ways to exercise opt-out rights, visit our
      <a
        class="customlinks"
        href="https://www.henkel-northamerica.com/privacy-statement-na"
        rel="nofollow"
        >Privacy Policy</a
      >
      <a
        class="customlinks"
        href="https://www.henkel-northamerica.com/ca-privacy-rights"
        rel="nofollow"
        >CA Privacy Notice</a
      >. <br />
      <br />
      If you have enabled regulator-approved privacy controls on your browser
      (such as a plugin), we accept signals it sends as a valid request to
      opt-out.
    </p>

    <ul>
      <li>
        <h4>Functional Cookies</h4>
        <p>
          These cookies enable the website to provide enhanced functionality and
          personalisation. They may be set by us or by third party providers
          whose services we have added to our pages. If you consent to these
          cookies, your data may also be transferred to countries outside the
          European Economic Area without an adequate level of data protection.
          You may not be able to exercise your rights in relation to the data
          and state authorities may have access to this data. If you do not
          allow these cookies, then some or all of these services may not
          function properly.
        </p>
      </li>
      <li>
        <h4>Performance Cookies</h4>
        <p>
          These cookies allow us to count visits and traffic sources so we can
          measure and improve the performance of our site. They help us to know
          which pages are the most and least popular and see how visitors move
          around the site. If you do not allow these cookies we will not be able
          to monitor its performance using these cookies.
        </p>
      </li>
      <li>
        <h4>Targeting Cookies</h4>
        <p>
          These cookies may be set through our site by us and/or our advertising
          partners, including social media companies. They can be used to build
          a profile of your interests based on the browsing information they
          collect from you when you visit a website, which includes uniquely
          identifying your browser and/or internet device and show you relevant
          content or adverts on our and other sites. If you do not allow these
          cookies, you will experience less targeted advertising.
        </p>
      </li>
    </ul>

    <table>
      <thead>
        <tr>
          <th scope="col">Cookie Subgroup</th>
          <th scope="col">Cookies</th>
          <th scope="col">Cookies used</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>tdx.henkel.com</td>
          <td>
            <a
              href="https://cookiepedia.co.uk/cookies/ln_or"
              rel="noopener"
              target="_blank"
              aria-label="ln_or Opens in a new Tab"
            >
              ln_or
            </a>
          </td>
          <td>First Party</td>
        </tr>
        <tr>
          <td>henkel.com</td>
          <td>
            <a
              href="https://cookiepedia.co.uk/cookies/mbox"
              rel="noopener"
              target="_blank"
              aria-label="mbox Opens in a new Tab"
            >
              mbox </a
            >,
            <a
              href="https://cookiepedia.co.uk/cookies/at_check"
              rel="noopener"
              target="_blank"
              aria-label="at_check Opens in a new Tab"
            >
              at_check </a
            >,
            <a
              href="https://cookiepedia.co.uk/cookies/_gclxxxx"
              rel="noopener"
              target="_blank"
              aria-label="_gclxxxx Opens in a new Tab"
            >
              _gclxxxx </a
            >,
            <a
              href="https://cookiepedia.co.uk/cookies/_mkto_trk"
              rel="noopener"
              target="_blank"
              aria-label="_mkto_trk Opens in a new Tab"
            >
              _mkto_trk </a
            >,
            <a
              href="https://cookiepedia.co.uk/cookies/_fbp"
              rel="noopener"
              target="_blank"
              aria-label="_fbp Opens in a new Tab"
            >
              _fbp </a
            >,
            <a
              href="https://cookiepedia.co.uk/cookies/AMCV_"
              rel="noopener"
              target="_blank"
              aria-label="AMCV_ Opens in a new Tab"
            >
              AMCV_
            </a>
          </td>
          <td>First Party</td>
        </tr>
        <tr>
          <td>
            <a
              href="https://cookiepedia.co.uk/host/assets.adobedtm.com"
              rel="noopener"
              target="_blank"
              aria-label="assets.adobedtm.com Opens in a new Tab"
            >
              assets.adobedtm.com
            </a>
          </td>
          <td>at_check, kndctr_6FE936A5559694837F000101_AdobeOrg_consent</td>
          <td>Third Party</td>
        </tr>
        <tr>
          <td>
            <a
              href="https://cookiepedia.co.uk/host/www.linkedin.com"
              rel="noopener"
              target="_blank"
              aria-label="www.linkedin.com Opens in a new Tab"
            >
              www.linkedin.com
            </a>
          </td>
          <td>bscookie</td>
          <td>Third Party</td>
        </tr>
        <tr>
          <td>
            <a
              href="https://cookiepedia.co.uk/host/linkedin.com"
              rel="noopener"
              target="_blank"
              aria-label="linkedin.com Opens in a new Tab"
            >
              linkedin.com
            </a>
          </td>
          <td>li_gc, lidc, bcookie, UserMatchHistory, AnalyticsSyncHistory</td>
          <td>Third Party</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="pageName === 'note-for-us-residents'">
    <div class="info-title">Note for US Residents</div>
    <img
      class="us-image"
      src="https://dm.henkel-dam.com/is/image/henkel/us-residents?wid=480&fmt=webp&qlt=90&fit=hfit"
    />
    <p>
      <a
        href="https://www.henkel-adhesives.com/us/en.html"
        target="_blank"
        rel="noopener noreferrer"
        >Please visit www.henkel-adhesives.us for links to Henkel Corporation
        and its products and services in the United States.
      </a>
    </p>
    <p>
      <a
        href="https://www.henkel-adhesives.com/us/en.html"
        target="_blank"
        rel="noopener noreferrer"
        >Learn more</a
      >
    </p>
    <p>
      These websites contain information and documents which are for information
      purposes only. They do not constitute an offer or an invitation to
      subscribe for, purchase, buy or hold any financial instruments –
      securities in particular. These information and documents are not directed
      at and may not be distributed to any person residing in the United States
      of America.
    </p>
  </div>
</div>
