export const environment = {
  production: false,
  config_portal: '/ccs',
  control_center:
    'https://weu-prod-control-center-2.wittybeach-64734bf1.westeurope.azurecontainerapps.io/',
  kafka_monitor: '_',
  katalon_dashboard:
    'https://dev.azure.com/henkeldx/iPaaS/_testManagement/analytics/progressreport',
  elastic_cloud: 'https://observability.raqn.io/elastic/',
  kafka_ui: '/kafkaui',
  supergraph_dev_tools: '/dev-tools-ui',
  api_management: 'https://developer.raqn.io/',
  ocp_apim_subscription_key: '76d09cd075d5405eb64265e86b06ddc2',
  clientId: 'f6cebac2-edc2-4144-8fa4-39ee8868385c',
  authority:
    'https://login.microsoftonline.com/e8701075-0d9e-4ea1-991d-5a0d110a5d29',
};
