import { Component, OnDestroy, OnInit } from '@angular/core';
import { LikesService } from './like.service';
import { CreateResourceDto, Resource } from './like.model';
import { MsalService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { JwtService } from '../../helpers/jwt.service';
import { firstValueFrom, Subscription } from 'rxjs';

type ProfileType = {
  givenName?: string;
  surname?: string;
  userPrincipalName?: string;
  id?: string;
  jobTitle?: string;
  displayName?: string;
  mail?: string;
};

@Component({
  selector: 'app-like',
  templateUrl: './like.component.html',
  styleUrl: './like.component.scss',
})
export class LikeComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  private intervalId: NodeJS.Timeout | null = null;

  resources: Resource[] = [];
  resourceID = 'raqn42';
  GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
  profile!: ProfileType | undefined;
  token = '';

  isLiked = false;
  likesNumber = 0;

  constructor(
    private http: HttpClient,
    private likesService: LikesService,
    private msalService: MsalService,
    private jwtService: JwtService,
  ) {}

  async ngOnInit() {
    await this.getProfileData();
    this.getLikesNumber();

    this.intervalId = setInterval(() => {
      this.getLikesNumber();
    }, 30000);

    this.likesService.getUserLikes(this.resourceID).subscribe((data) => {
      this.isLiked = data.length > 0;
    });
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    this.subscription.unsubscribe();
  }

  updateLikeResource = (): any => {
    if (!this.isLiked) {
      const likeResource: CreateResourceDto = {
        remoteUniqueId: this.resourceID,
        description: 'raqn-apps',
      };

      this.likesService.likeResource(likeResource).subscribe((data) => {
        this.isLiked = true;
        this.getLikesNumber();
      });
    } else {
      this.likesService
        .unlikeResource({ remoteUniqueId: this.resourceID })
        .subscribe((data) => {
          this.isLiked = false;
          this.getLikesNumber();
        });
    }
  };

  getLikesNumber = () => {
    const getLikesSubscription = this.likesService
      .getResourcesWithLikes(this.resourceID)
      .subscribe((data) => {
        this.resources = data;
        this.likesNumber = data[0].metadata.likeCount;
      });

    this.subscription.add(getLikesSubscription);
  };

  getProfileData = async () => {
    if (this.msalService.instance.getAllAccounts().length > 0) {
      try {
        this.profile = await firstValueFrom(this.http.get(this.GRAPH_ENDPOINT));

        if (this.profile?.id) {
          this.token = await this.jwtService.generateToken(this.profile.id);
        }
      } catch (error) {
        console.error(
          'Error retrieving profile data or generating token:',
          error,
        );
      }
    }
  };
}
