import { Routes } from '@angular/router';
import { InfoComponent } from './components/info/info.component';
import { MsalGuard } from '@azure/msal-angular';

export const routes: Routes = [
  { path: 'info/:name', canActivate: [MsalGuard], component: InfoComponent },
  {
    path: '',
    redirectTo: '/info/main',
    pathMatch: 'full',
  },
  { path: '**', redirectTo: '/info/main' },
];
