import { Injectable } from '@angular/core';
import { SignJWT } from 'jose';

@Injectable({
  providedIn: 'root',
})
export class JwtService {
  private secret = new TextEncoder().encode('my_very_secret_raqn_key');

  constructor() {}

  async generateToken(sub: string): Promise<string> {
    const payload = {
      sub: sub,
    };

    const token = await new SignJWT(payload)
      .setProtectedHeader({ alg: 'HS256' })
      .sign(this.secret);

    return token;
  }
}
