import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (req.url.includes('raqn')) {
      const apiReq = req.clone({
        setHeaders: {
          'ocp-apim-subscription-key': '76d09cd075d5405eb64265e86b06ddc2',
        },
      });

      return next.handle(apiReq);
    }

    return next.handle(req);
  }
}
